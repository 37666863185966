<template>
  <div>
    <v-card>
      <v-card-title class="d-flex justify-content-between">
        <span> Lançamento de vales para exportação </span>
        <v-spacer></v-spacer>
        <v-btn
          color="info"
          outlined
          @click="openModal"
        >
          <v-icon
            size="30"
            class="me-2"
          >
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="itemsTable"
        :loading="isLoading"
        loading-text="Carregando dados..."
      >
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.value="item">
          {{ Number(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
        </template>

        <template v-slot:item.value_paid="item">
          {{ Number(item.item.value_paid).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
        </template>

        <template v-slot:item.edit="{ item }">
          <v-icon
            medium
            class="me-2"
            @click="openModalDescription(item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-icon
            medium
            class="me-2"
            color="error"
            @click="deleteRegister(endpointDelete, item.id)"
          >
            {{ icons.mdiDelete }}
          </v-icon>
        </template>

        <template v-slot:item.description="{ item }">
          <div>
            {{ removeHtmlInString(item.description) }}
          </div>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ dateFormat(item.created_at) }}
        </template>
      </v-data-table>

      <v-dialog
        v-model="showModal"
        width="700px"
        persistent
      >
        <ModalHome
          :sectors-list="sectorsList"
          :voucher-types-list="voucherTypesList"
          :list-of-installments="listOfInstallments"
          @updatedTable="updatedTable()"
          @close="showModal = false"
        ></ModalHome>
      </v-dialog>

      <v-dialog
        v-model="showModalDescription"
        width="700px"
        persistent
      >
        <ModalDescription
          :key="dataDetails.id"
          :data="dataDetails"
          :sectors-list="sectorsList"
          :voucher-types-list="voucherTypesList"
          :list-of-installments="listOfInstallments"
          @updatedTable="updatedTable()"
          @close="showModalDescription = false"
        ></ModalDescription>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'
import LocalStorageSlim from 'localstorage-slim'
import ModalDescription from './ModalDescription.vue'
import ModalHome from './ModalHome.vue'

export default {
  components: {
    ModalHome,
    ModalDescription,
  },

  mixins: [formatters, messages],

  data() {
    return {
      lastStatus: '',
      dataDetails: '',
      loadingCircleChat: '',
      hierarchicalProfile: '',

      sectorsList: [],
      userProfile: [{}],
      voucherTypesList: [],
      listOfInstallments: [],

      endpointDelete: 'api/v1/integration/vale/destroy',

      headers: [
        {
          text: 'TIPO',
          value: 'type_voucher.description',
          sortable: false,
          align: '',
        },
        {
          text: 'VALOR',
          value: 'value',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DATA DA SOLICITAÇÃO',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EDITAR',
          value: 'edit',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DELETAR',
          value: 'delete',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      isLoading: false,
      showModal: false,
      showModalDescription: false,

      icons: {
        mdiPencil,
        mdiDelete,
        mdiPlaylistPlus,
      },
    }
  },

  created() {
    this.userProfile = LocalStorageSlim.get('userProfile', { decrypt: true })
    this.hierarchicalProfile = this.userProfile.hierarchical_profile

    this.getItemsTable()
    this.getSectorsList()
    this.getVoucherTypes()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns.get('/api/v1/integration/vale/launch_index').then(({ data }) => {
        this.itemsTable = data.data
      }).catch(error => {
        this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
      }).finally(() => {
        this.isLoading = false
      })
    },

    async getSectorsList() {
      await axiosIns.get('api/v1/records/sector/index').then(response => {
        this.sectorsList = response.data.data
      }).catch(error => {
        this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, icon: 'error' })
      })
    },

    async getVoucherTypes() {
      await axiosIns.get('api/v1/integration/type_voucher/index').then(response => {
        this.voucherTypesList = response.data.data

        response.data.data.map(key => {
          this.listOfInstallments.push({
            id: key?.id,
            number_parcel: key?.number_parcel,
            minimum_value_parcel: key?.minimum_value_parcel,
            event: key.event,
          })
        })
      })
    },

    updatedTable() {
      this.getItemsTable()
    },

    openModal() {
      this.showModal = true
    },

    openModalDescription(item) {
      this.showModalDescription = true
      this.loadingCircleChat = item.id
      this.dataDetails = item
    },

    closeModal() {
      this.dataDetails = ''
      this.loadingCircleChat = ''
      this.showModalDescription = false
    },
  },
}
</script>
