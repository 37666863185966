import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" Lançamento de vales para exportação ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"info","outlined":""},on:{"click":_vm.openModal}},[_c(VIcon,{staticClass:"me-2",attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" Novo ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.isLoading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.value",fn:function(item){return [_vm._v(" "+_vm._s(Number(item.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")]}},{key:"item.value_paid",fn:function(item){return [_vm._v(" "+_vm._s(Number(item.item.value_paid).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":""},on:{"click":function($event){return _vm.openModalDescription(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.deleteRegister(_vm.endpointDelete, item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.removeHtmlInString(item.description))+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.created_at))+" ")]}}])}),_c(VDialog,{attrs:{"width":"700px","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('ModalHome',{attrs:{"sectors-list":_vm.sectorsList,"voucher-types-list":_vm.voucherTypesList,"list-of-installments":_vm.listOfInstallments},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showModal = false}}})],1),_c(VDialog,{attrs:{"width":"700px","persistent":""},model:{value:(_vm.showModalDescription),callback:function ($$v) {_vm.showModalDescription=$$v},expression:"showModalDescription"}},[_c('ModalDescription',{key:_vm.dataDetails.id,attrs:{"data":_vm.dataDetails,"sectors-list":_vm.sectorsList,"voucher-types-list":_vm.voucherTypesList,"list-of-installments":_vm.listOfInstallments},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showModalDescription = false}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }