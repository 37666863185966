<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Vales para exportação
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            v-model="inputValue"
            label="Valor"
            type="number"
            prefix="$"
            :rules="[rules.required]"
            outlined
            dense
          />

          <v-autocomplete
            v-model="requesterIdSelected"
            :items="employeeList"
            item-text="name"
            item-value="id"
            label="Solicitante"
            :rules="[rules.required]"
            outlined
            clearable
            dense
          />

          <v-autocomplete
            v-model="leaderIdSelected"
            :items="employeeList"
            item-text="name"
            item-value="id"
            label="Líder"
            :rules="[rules.required]"
            clearable
            outlined
            dense
          />

          <v-autocomplete
            v-model="financialIdSelected"
            :items="employeeList"
            item-text="name"
            item-value="id"
            label="Responsável pelo pagamento"
            :rules="[rules.required]"
            clearable
            outlined
            dense
          />
        </v-col>

        <v-col>
          <v-autocomplete
            v-model="directorIdSelected"
            :items="employeeList"
            item-text="name"
            item-value="id"
            label="Diretor"
            :rules="[rules.required]"
            clearable
            outlined
            dense
          />

          <v-autocomplete
            v-model="sectorIdSelected"
            :items="sectorsList"
            item-text="name"
            item-value="id"
            label="Setor"
            :rules="[rules.required]"
            clearable
            outlined
            dense
          />

          <v-autocomplete
            v-model="voucherTypeSelected"
            :items="voucherTypesList"
            item-text="description"
            item-value="id"
            label="Tipo de vale"
            :rules="[rules.required]"
            clearable
            outlined
            dense
          />

          <v-dialog
            ref="dialog"
            v-model="showCalendar"
            :return-value.sync="dateInput"
            persistent
            width="400px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Data de pagamento"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
              >
                <template v-slot:append>
                  <v-icon>{{ icons.mdiCalendar }}</v-icon>
                </template>
              </v-text-field>
            </template>

            <v-date-picker
              v-model="dateInput"
              width="400px"
              locale="pt-BR"
              scrollable
              @dblclick:date="handleMonthSelection()"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="showCalendar = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.dialog.save(dateInput)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>

      <v-autocomplete
        v-show="showInstallmentsField"
        v-model="installmentsNumberSelected"
        :items="numberParcels"
        label="Número de parcelas"
        class="mt-2"
        data-test=""
        clearable
        outlined
        dense
      />

      <vue-editor v-model="inputDescription"></vue-editor>

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          @click="closeModal"
        >
          Cancelar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiCalendar } from '@mdi/js'
import localStorageSlim from 'localstorage-slim'
import { VueEditor } from 'vue2-editor'

export default {
  components: {
    VueEditor,
  },

  mixins: [formatters, messages],

  props: {
    sectorsList: {
      type: [Array, Object],
      required: true,
    },

    voucherTypesList: {
      type: [Array, Object],
      required: true,
    },

    listOfInstallments: {
      type: [Array, Object, String],
      required: true,
    },
  },

  data() {
    return {
      dateInput: '',
      inputValue: '',
      inputDescription: '',
      minimumInstallmentValue: '',

      leaderIdSelected: '',
      sectorIdSelected: '',
      directorIdSelected: '',
      requesterIdSelected: '',
      financialIdSelected: '',
      voucherTypeSelected: '',
      installmentsNumberSelected: '',

      bodyArrayEmpty: [],
      numberParcels: [],
      employeeList: [],

      isLoading: false,
      showCalendar: false,
      showInstallmentsField: false,
      showMessageMinimumParcelValue: false,

      icons: {
        mdiCalendar,
      },
    }
  },

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.dateInput)
    },
  },

  watch: {
    voucherTypeSelected(newValue) {
      this.validateInstallments(newValue)
    },
  },

  created() {
    this.employeeList = localStorageSlim.get('listEmployees', { decrypt: true })
  },

  methods: {
    async sendModalData() {
      const body = {
        users_id: this.requesterIdSelected,
        description: this.inputDescription,
        value: Number(this.inputValue),
        value_paid: Number(this.inputValue),
        status: 'SOLICITADO',

        for_users_leader: this.leaderIdSelected,
        description_leader: 'APROVADO',
        status_leader: 'APROVADO',
        date_leader: this.formatDateUSA(this.computedDateFormatted),

        for_users_director: this.directorIdSelected,
        description_director: 'APROVADO',
        status_director: 'APROVADO',
        date_director: this.formatDateUSA(this.computedDateFormatted),

        for_users_financial: this.financialIdSelected,
        description_financial: 'PAGO',
        status_financial: 'PAGO',
        date_financial: this.formatDateUSA(this.computedDateFormatted),

        sectors_id: this.sectorIdSelected,
        types_vouchers_id: this.voucherTypeSelected,
        number_parcel: !this.installmentsNumberSelected ? 1 : this.installmentsNumberSelected,
        launch: true,
      }

      this.validateRequisitionBody(body)

      if (this.bodyArrayEmpty.length === 0) {
        this.isLoading = true

        await axiosIns
          .post('/api/v1/integration/vale/store', body)
          .then(() => {
            this.showMessage({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua solicitação de vale foi enviada com sucesso!',
            })

            this.updatedTable()
          })
          .catch(error => {
            if (error.response.status === 406) {
              const errorMessage = error.response.data.data.mensage
              this.showMessage({
                icon: 'warning',
                title: 'Valor de parcela abaixo do permitido!',
                text: `${errorMessage}`,
                timer: 5000,
              })

              return
            }

            this.showMessage({
              icon: 'error',
              title: 'Erro ao solicitar o vale!',
              text: error,
            })
          })

        this.isLoading = false
        this.closeModal()

        return
      }

      this.bodyArrayEmpty = []

      this.showMessage({
        title: 'Campos vazios, preencha todos!',
        icon: 'error',
      })

      this.isLoading = false
    },

    handleMonthSelection() {
      this.$refs.dialog.save(this.dateInput)
      this.showCalendar = false
    },

    /**
     * @param {object.<string | number>} body
     */
    validateRequisitionBody(body) {
      Object.keys(body).forEach(item => {
        if (!body[item]) {
          this.bodyArrayEmpty.push(item)
        }
      })
    },

    /**
     * Cria um array de números inteiros em uma sequência aritmética.
     *
     * @param {number} start - O número inicial da sequência.
     * @param {number} end - O número final da sequência (não incluído no array resultante).
     * @returns {Array.<number>} Um array de números inteiros em uma sequência aritmética.
     *
     * @example
     * range(1, 5); // [1, 2, 3, 4]
     */
    range(start, end) {
      return Array.apply(1, Array(end)).map((_element, index) => index + start)
    },

    showInstallmentError(errorMessage) {
      this.showMessage({
        icon: 'warning',
        title: 'Valor de parcela abaixo do permitido!',
        text: `${errorMessage}`,
        timer: 5000,
      })
    },

    validateInstallments(id) {
      this.listOfInstallments.map(key => {
        if (key?.id === id) {
          if (key.number_parcel > 1) {
            this.showInstallmentsField = true
            this.numberParcels = this.range(1, key.number_parcel)
            this.numberParcels.shift()

            if (key.minimum_value_parcel > 0) {
              this.minimumInstallmentValue = `Valor mínimo da parcela é de ${key.minimum_value_parcel} reais`
              this.showMessageMinimumParcelValue = true

              return
            }

            this.showMessageMinimumParcelValue = false

            return
          }

          this.showInstallmentsField = false
        }
      })
    },

    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')

      return `${day}/${month}/${year}`
    },

    formatDateUSA(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month}-${day}`
    },

    closeModal() {
      this.$emit('close')

      this.inputDescription = ''
      this.inputValue = ''
      this.sectorIdSelected = ''
      this.voucherTypeSelected = ''
    },
  },
}
</script>

<style>
/* Retira o incrementador padrão dos inputs do tipo "number"*/
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>
