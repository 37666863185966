<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Vales para exportação
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field
            v-model="data.value"
            label="Valor"
            type="number"
            prefix="$"
            outlined
            dense
          />

          <v-text-field
            v-model="data.user.name"
            label="Solicitante"
            outlined
            readonly
            dense
          />

          <v-text-field
            v-model="data.user_leader.name"
            label="Líder"
            outlined
            readonly
            dense
          />

          <v-text-field
            v-model="data.user_financial.name"
            label="Responsável pelo pagamento"
            outlined
            readonly
            dense
          />
        </v-col>

        <v-col>
          <v-text-field
            v-model="data.user_director.name"
            label="Diretor"
            outlined
            readonly
            dense
          />

          <v-text-field
            v-model="data.sector.name"
            label="Setor"
            outlined
            readonly
            dense
          />

          <v-text-field
            v-model="data.type_voucher.description"
            label="Tipo de vale"
            outlined
            readonly
            dense
          />

          <v-text-field
            v-model="computedFinancialDateFormatted"
            label="Data de pagamento"
            outlined
            readonly
            dense
          />
        </v-col>
      </v-row>

      <v-text-field
        v-model="computedDataDescription"
        label="Descrição"
        outlined
        readonly
        dense
      />

      <div class="d-flex justify-end mt-5">
        <v-btn
          color="error"
          class="ml-5"
          @click="closeModal()"
        >
          Cancelar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import formatters from '@/plugins/formattersMixin1'

export default {
  mixins: [formatters],

  props: {
    data: {
      type: [Array, Object],
      required: true,
    },
  },

  computed: {
    computedDataDescription() {
      return this.removeHtmlInString(this.data.description)
    },

    computedFinancialDateFormatted() {
      return this.dateFormat(this.data.date_financial)
    },
  },

  methods: {
    dateFormat(date) {
      const dateSliced = date.slice(0, 10)

      const dateDivided = dateSliced.split('-')

      const dateFormated = `${dateDivided[2]}/${dateDivided[1]}/${dateDivided[0]}`

      return dateFormated
    },
  },
}
</script>
